import Keycloak from "keycloak-js";

const keyCloakInstance = new Keycloak();

const login = (onAuthenticatedCallback) =>
{
    keyCloakInstance
    .init({
        onLoad: "login-required"
    })
    .then(function (authenticated)
    {
        onAuthenticatedCallback(authenticated && validateAccessRole());
    })
    .catch(error =>
    {
        console.dir(error);
        console.log(`Keycloak init exception: ${error}`);
    });
};

const logout = () => keyCloakInstance.logout();

const userName = () => keyCloakInstance.tokenParsed?.name ?? keyCloakInstance.tokenParsed?.preferred_username;

const isLoggedIn = () => !!keyCloakInstance.token;

const getSessionId = () => keyCloakInstance.sessionId;

const getToken = () => keyCloakInstance.token;

const getTokenParsed = () => keyCloakInstance.tokenParsed;

const updateToken = (successCallback) =>
    keyCloakInstance.updateToken(5)
                    .then(successCallback)
                    .catch(() => keyCloakInstance.login());

function validateAccessRole()
{
    if (keyCloakInstance.clientId
        && keyCloakInstance.resourceAccess
        && keyCloakInstance.resourceAccess[keyCloakInstance.clientId]
        && keyCloakInstance.resourceAccess[keyCloakInstance.clientId].roles.includes("access"))
        return true;

    return false;
}

function isAdmin()
{
    if (keyCloakInstance.clientId
        && keyCloakInstance.resourceAccess
        && keyCloakInstance.resourceAccess[keyCloakInstance.clientId]
        && keyCloakInstance.resourceAccess[keyCloakInstance.clientId].roles.includes("admin"))
        return true;
    return false;
}

const KeycloakService = {
    callLogin: login,
    callLogout: logout,
    getUserName: userName,
    isAdmin: isAdmin,
    isLoggedIn: isLoggedIn,
    getSessionId: getSessionId,
    getToken: getToken,
    getTokenParsed: getTokenParsed,
    updateToken: updateToken
};

export default KeycloakService;
