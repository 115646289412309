import {createTheme} from "@mui/material";

import {grey, yellow} from '@mui/material/colors';

import roboto from "../fonts/Roboto-Regular.ttf";

export const globalTheme = createTheme({
    palette: {
        primary: {
            main: grey["100"],
            light: grey["50"],
            dark: grey["600"],
            tileBg: grey["200"],
            selected: grey["300"],
            hover: grey["400"]
        },
        secondary: {
            main: "#ff5D64",
            alternative: '#4947d2',
            selected: yellow["700"]
        },
        background: {
            default: grey["200"]
        }
    },
    font: {
        heading: {
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': {
                    fontFamily: 'Roboto',
                    src: `local('Roboto-Regular.ttf'), url(${roboto})`
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: grey["50"]
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: grey["50"]
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    color: grey["900"]
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: grey["900"]
                }
            }
        }
    }
});
